


















































import Vue from "vue";
import { DataTableHeader } from "vuetify";
import AddSeriesDialog from "@/components/AddSeriesDialog.vue";
import EditSeriesDialog from "@/components/EditSeriesDialog.vue";
import { MediaType } from "@/store/interfaces/MediaType";

export default Vue.extend({
  components: {
    AddSeriesDialog,
    EditSeriesDialog,
  },
  async beforeMount() {
    await this.$store.dispatch("getSeries");
    await this.$store.dispatch("getCoaches");
    await this.$store.dispatch("getTags");
  },
  data: () => ({
    editIndex: 0,
    editDialog: false,
    addDialog: false,
    headers: [
      {
        text: "",
        value: "coverPicSrc",
        width: 120,
        sortable: false,
      },
      {
        text: "Nom",
        value: "name",
      },
      {
        text: "",
        value: "tools",
        width: 50,
      },
    ] as DataTableHeader[],
  }),
  methods: {
    postUpdateCycle() {
      this.$store.dispatch("getSeries");
    },
    openEditDialog(item: Record<string, any>) {
      this.editIndex = item.id;
      this.editDialog = true;
    },
    closeEditDialog() {
      this.editIndex = 0;
      this.editDialog = false;
    },
  },
});
