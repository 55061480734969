






































































































































































































import base64Images from "@/modules/base64-images";
import Request from "@/modules/request";
import { MediaType } from "@/store/interfaces/MediaType";
import CategoryPackItem from "@/store/interfaces/CategoryPackItem";
import Vue from "vue";
import Pack from "@/store/models/Pack";
import PackDescriptionItem from "@/store/interfaces/PackDescriptionItem";
import Category from "@/store/models/Category";
import Series from "@/store/models/Series";
import { VideoType } from "@/store/interfaces/VideoType";
import Video from "@/store/models/Video";
import AddVideoDialog from "@/components/AddVideoDialog.vue";
import EditVideoDialog from "@/components/EditVideoDialog.vue";
import { Level } from "@/store/interfaces/Level";
import rules from "@/modules/rules";

export default Vue.extend({
  components: {
    AddVideoDialog,
    EditVideoDialog,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    coverFile: {
      get(): File | null {
        return this.cover as File;
      },
      set(value: File) {
        try {
          if (value) {
            if (this.validateThumbnail(value)) {
              this.cover = value;
              this.coverSrc = URL.createObjectURL(value);
              this.coverChanged = true;
            } else {
              this.cover = null;
              this.coverSrc = base64Images.thumbnailPlaceholder;
            }
          } else {
            this.cover = value;
            this.coverSrc = base64Images.thumbnailPlaceholder;
            this.coverChanged = true;
          }
        } catch (error) {
          this.$store.dispatch("showAlert", {
            message: error.message,
            color: "warning",
            timeout: 4000,
          });
        }
      },
    },
    showDialog: {
      get(): boolean {
        return this.show;
      },
      set(value: boolean) {
        if (!value) {
          this.series = null;
          this.seriesObjectLoaded = false;
          this.seriesObjectChanged = false;
          this.coverSrc = base64Images.thumbnailPlaceholder;
          this.trailerPicSrc = base64Images.thumbnailPlaceholder;
          this.videoContent = [];
          (this.$refs.editSeriesForm as any).resetValidation();
          this.$emit("close");
          setTimeout(() => {
            this.coverFile = null;
            this.coverChanged = false;
            this.updateLoading = false;
          }, 300);
        }
      },
    },
  },
  data() {
    return {
      monitor: 0,
      editIndex: 0,
      editDialog: false,
      showAddTrailer: false,
      showAddVideo: false,
      series: null as null | Series,
      seriesObjectLoaded: false,
      seriesObjectChanged: false,
      coverSrc: base64Images.thumbnailPlaceholder,
      coverChanged: false,
      cover: null as File | null,
      trailerPicSrc: base64Images.thumbnailPlaceholder,
      trailerChanged: false,
      deleteDialog: false,
      deleteLoading: false,
      updateLoading: false,
      videoContent: [] as Video[],
      headers: [
        {
          text: "",
          value: "thumbSrc",
          width: 200,
          sortable: false,
        },
        {
          text: "Difficulté",
          value: "difficulty",
          width: 50,
          sortable: false,
        },
        {
          text: "Titre",
          value: "name",
        },
        {
          text: "Encodage",

          value: "encodingString",
        },
        {
          text: "Publique",
          value: "available",
          align: "center",
          width: 50,
        },
      ],
      valid: false,
      rules: rules(this),
    };
  },
  watch: {
    async id() {
      // If ID, then we fetch the details
      if (this.id) {
        // Fetch video object
        this.getSeries();
      }
    },
    series: {
      deep: true,
      handler(newValue, oldValue) {
        if (oldValue && this.seriesObjectLoaded) {
          this.seriesObjectChanged = true;
        }
      },
    },
  },
  methods: {
    getEncodingStatus,
    formattedDuration,
    getDifficultyIcon,
    async changeTrailer(action: string) {
      switch (action) {
        case "remove":
          await removeTrailer(this.series?.id, this.series?.Trailer?.id);
          this.trailerPicSrc = base64Images.thumbnailPlaceholder;
          await this.getSeries();
          break;
        case "add-edit":
          if (this.series?.Trailer) {
            this.openEditDialog(this.series.Trailer);
          } else {
            this.showAddTrailer = true;
          }
      }
    },
    async closeAddVideoDialog(videoId?: number) {
      try {
        // If there is a video ID, it means that a new video has been uploaded to the series, we need to assign it and refresh the videos
        if (videoId) {
          await Request.shared.post(`series/${this.id}/video/${videoId}`);
          this.getSeries();
        }
      } catch (error) {
        console.error(error);
      }
      this.showAddVideo = false;
    },
    async closeAddTrailerDialog(videoId?: number) {
      try {
        // If there is a video ID, it means that a new trailer is uploaded
        if (videoId) {
          await Request.shared.put(`series/${this.id}/trailer/${videoId}`);
          this.getSeries();
        }
      } catch (error) {
        console.error(error);
      }
      // If there is a video ID, the trailer has been updated
      this.showAddTrailer = false;
    },
    postUpdateCycle() {
      this.getSeries();
    },
    closeEditDialog() {
      if (this.monitor) {
        clearInterval(this.monitor);
        this.monitor = 0;
      }
      this.editIndex = 0;
      this.editDialog = false;
    },
    openEditDialog(item: Record<string, any>) {
      this.editIndex = item.id;
      this.editDialog = true;
    },
    async updateSeries() {
      try {
        this.updateLoading = true;
        if (this.coverChanged) {
          if (!this.coverFile) {
            const thumbnailDeleted = await Request.shared.put(
              `series/${this.series?.id}/coverpic`
            );
          } else {
            // The user has set a new thumbnail, we need to create the media and assign it to the video
            const mediaBody = {
              type: MediaType.cover,
              alt: this.coverFile.name,
            };
            // We create the formdata
            const formData = new FormData();
            formData.append("body", JSON.stringify(mediaBody));
            formData.append("media", this.coverFile);
            const media = await Request.shared.post("media", formData);
            // Once we got the media, we can assign the thumbnail to the video
            const assignedMedia = await Request.shared.put(
              `series/${this.series?.id}/coverpic/${media.data.id}`
            );
          }
        }
        await Request.shared.put(`series/${this.id}`, { ...this.series });
        // Rebuild original object
        await this.getSeries();
        this.$emit("updated");
        this.coverChanged = false;
        const data = this.$data;
        setTimeout(() => {
          data.seriesObjectChanged = false;
          this.updateLoading = false;
        }, 100);
      } catch (error) {
        console.error(error);
        this.updateLoading = false;
      }
    },
    async getSeries() {
      let needsMonitoring = false;
      try {
        this.seriesObjectLoaded = false;
        const series = await Request.shared.get(`series/${this.id}`);
        this.series = series.data;

        if (this.series!.CoverPic) {
          try {
            const imageRequest = await Request.shared.get(
              `content/${this.$store.state.admin.uuid}/media/${this.series?.CoverPic.filename}`,
              {
                responseType: "blob",
              }
            );
            this.coverSrc = URL.createObjectURL(imageRequest.data);
          } catch (error) {
            console.log(error);
            // Show alert but don't close
          }
        }
        // If there is a trailer and it has a thumbnail
        if (this.series!.Trailer?.Thumbnail) {
          try {
            const imageRequest = await Request.shared.get(
              `content/${this.$store.state.admin.uuid}/media/${
                this.series!.Trailer?.Thumbnail.filename
              }`,
              {
                responseType: "blob",
              }
            );
            this.trailerPicSrc = URL.createObjectURL(imageRequest.data);
          } catch (error) {
            console.log(error);

            // Show alert but don't close
          }
        }

        // Getting series video content
        this.videoContent = await getVideos(
          this.id,
          this.$store.state.admin.uuid
        );
        //
        for (const video of this.videoContent) {
          if (!video.encoded) {
            needsMonitoring = true;
          }
        }
        if (needsMonitoring) {
          this.setVideoMonitoring();
        } else if (this.monitor) {
          clearInterval(this.monitor);
        }

        const data = this.$data;
        setTimeout(() => {
          data.seriesObjectLoaded = true;
        }, 100);
      } catch (error) {
        this.updateLoading = false;
        console.error(error);
      }
    },
    async setVideoMonitoring() {
      if (!this.monitor) {
        this.monitor = setInterval(async () => {
          // Getting series video content
          this.videoContent = await getVideos(
            this.id,
            this.$store.state.admin.uuid
          );
        }, 10000) as unknown as number;
      }
    },
    async deleteSeries(id: number) {
      this.deleteLoading = true;
      await Request.shared.delete(`series/${id}`);
      await this.$store.dispatch("getSeries");
      this.deleteLoading = false;
      this.showDialog = false;
    },
    selectImage(picker: string) {
      ((this.$refs as any)[picker] as any).$refs.input.click();
    },
    handleDrop(e: DragEvent, field: string) {
      if (e.dataTransfer!.files.length) {
        // Switch field
        switch (field) {
          case "cover":
            this.coverFile = e.dataTransfer!.files[0];
            break;
          case "trailer":
          // TBD
        }
        //this.thumbnailFile = e.dataTransfer!.files[0];
      }
    },
    validateThumbnail(file: File) {
      if (file.type == "image/png" || file.type == "image/jpeg") {
        // We check the file size
        if (file.size < 300000) {
          return true;
        } else {
          throw {
            message:
              "Le fichier est trop volumineux, sa taille doit être inférieure à 300ko",
          };
        }
      } else {
        throw {
          message: "Veuillez utiliser un fichier .jpg ou .png",
        };
      }
    },
    async toggleVisibility(item: Video) {
      try {
        await Request.shared.put(`video/${item.id}`, {
          available: item.available,
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
});

async function getVideos(id: number, adminUUID: string) {
  if (id) {
    const videoRequest = await Request.shared.get(`series/${id}/video`);
    const videos = videoRequest.data;
    for (const video of videos as Video[]) {
      // We fetch the thumbnail if exists
      if (!video.Thumbnail) {
        video.thumbnailSrc = base64Images.thumbnailPlaceholder;
      } else {
        const blob = await Request.shared.get(
          `content/${adminUUID}/media/${video.Thumbnail.filename}`,
          {
            responseType: "blob",
          }
        );
        video.thumbnailSrc = URL.createObjectURL(blob.data);
      }
    }

    return videos;
  } else {
    return [];
  }
}
function getEncodingStatus(item: Video) {
  if (item.encodingError) {
    return "Erreur!";
  } else if (item.needsEncoding) {
    return "En attente...";
  } else if (!item.encoded) {
    return `En cours : ${item.encodingProgress}%`;
  } else if (item.encoded) {
    return "Terminé";
  } else {
    return "Inconnu";
  }
}

function formattedDuration(length: number) {
  const seconds = length % 60;
  const minutes = Math.floor((length / 60) % 60);
  const hours = Math.floor(length / 3600);
  return `${hours ? `${hours}:` : ""}${
    minutes < 10 ? `0${minutes}` : minutes
  }:${seconds < 10 ? `0${seconds}` : seconds}`;
}

function getDifficultyIcon(difficulty: Level) {
  switch (difficulty) {
    case Level.rookie:
      return "mdi-signal-cellular-1";
    case Level.intermediate:
      return "mdi-signal-cellular-2";
    case Level.pro:
      return "mdi-signal-cellular-3";
  }
}

async function removeTrailer(seriesId?: number, trailerId?: number) {
  if (trailerId && seriesId) {
    await Request.shared.put(`series/${seriesId}/trailer`);
    await Request.shared.delete(`video/${trailerId}`);
  }
}
